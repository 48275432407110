import FeatureCardStep1 from "./Cards/Step1/index.vue";
import FeatureCardStep2 from "./Cards/Step2/index.vue";
import FeatureCardStep3 from "./Cards/Step3/index.vue";
import FeatureCardStep4 from "./Cards/Step4/index.vue";
import FeatureCardStep5 from "./Cards/Step5/index.vue";
import FeatureCardStep6 from "./Cards/Step6/index.vue";
import FeatureCardStep7 from "./Cards/Step7/index.vue";

export const featuresList = [
  {
    title: "Measurable Results Per Dollar",
    description:
      "Every donation creates a tangible result that you can actually see! ",
    component: FeatureCardStep1,
  },
  {
    title: "Track your impact & Rise up the leaderboard",
    description:
      "Grow your impact stats over time and level-up at making the world a better place.",
    component: FeatureCardStep2,
  },
  {
    title: "Expert Vetted Projects",
    description:
      "Every project is vetted by globally renowned experts to ensure every dollar actually makes a difference.",
    component: FeatureCardStep3,
  },
  {
    title: "Transparent Tracking",
    description:
      "Don’t just “trust” that it made a difference, track it yourself!",
    component: FeatureCardStep4,
  },
  {
    title: "Solving Global Challenges",
    description:
      "Every project fits into our ‘Humanity’s Checklist’ roadmap. This club is on a mission to solve humanity’s biggest challenges!",
    component: FeatureCardStep5,
  },
  {
    title: "Unified Giving",
    description:
      "By pooling your donation together with others, we are creating the world’s first collective super-philanthropist, hyper-funding the best solutions!",
    component: FeatureCardStep6,
  },
  {
    isLast: true,
    title: "Grow Your Impact Family",
    description:
      "Build your Impact Family by inspiring others to join with $1/mo with your unique link and track your overall ripples of impact! ",
    component: FeatureCardStep7,
  },
];
