<template>
  <svg
    width="42"
    height="34"
    viewBox="0 0 42 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_62_41772)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37 6.23261L13.2579 29L2 18.2043L6.41382 13.9716L13.2579 20.5348L32.5862 2L37 6.23261Z"
        fill="currentColor"
      />
      <path
        d="M37.3461 6.5935L37.7224 6.23261L37.3461 5.87173L32.9323 1.63912L32.5862 1.30726L32.2401 1.63912L13.2579 19.842L6.75988 13.6108L6.41382 13.2789L6.06775 13.6108L1.65393 17.8434L1.2776 18.2043L1.65393 18.5651L12.9119 29.3609L13.2579 29.6927L13.604 29.3609L37.3461 6.5935Z"
        stroke="#16114F"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_62_41772"
        x="0.554688"
        y="0.614502"
        width="40.8906"
        height="32.771"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3" dy="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0862745 0 0 0 0 0.0666667 0 0 0 0 0.309804 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_62_41772"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_62_41772"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CustomCheckmarkStrokeIcon",
};
</script>
