<template>
  <svg
    width="21"
    height="15"
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_62_41709)">
      <mask id="path-1-inside-1_62_41709" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.34192 5.46858C8.87626 4.92487 9.20587 4.17931 9.20587 3.35676C9.20587 1.69272 7.8569 0.34375 6.19286 0.34375C4.52882 0.34375 3.17985 1.69272 3.17985 3.35676C3.17985 4.19804 3.52465 4.9588 4.08064 5.50541C2.20138 6.33932 0.890625 8.2216 0.890625 10.41C0.890625 10.4297 0.890731 10.4493 0.890942 10.4689H11.6166C11.6168 10.4493 11.6169 10.4297 11.6169 10.41C11.6169 8.18875 10.2665 6.28288 8.34192 5.46858Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.34192 5.46858C8.87626 4.92487 9.20587 4.17931 9.20587 3.35676C9.20587 1.69272 7.8569 0.34375 6.19286 0.34375C4.52882 0.34375 3.17985 1.69272 3.17985 3.35676C3.17985 4.19804 3.52465 4.9588 4.08064 5.50541C2.20138 6.33932 0.890625 8.2216 0.890625 10.41C0.890625 10.4297 0.890731 10.4493 0.890942 10.4689H11.6166C11.6168 10.4493 11.6169 10.4297 11.6169 10.41C11.6169 8.18875 10.2665 6.28288 8.34192 5.46858Z"
        fill="currentColor"
      />
      <path
        d="M8.34192 5.46858L8.09739 5.22825L7.74397 5.58787L8.20832 5.78433L8.34192 5.46858ZM4.08064 5.50541L4.2197 5.8188L4.68047 5.61434L4.321 5.26093L4.08064 5.50541ZM0.890942 10.4689L0.548104 10.4726L0.551753 10.8118H0.890942V10.4689ZM11.6166 10.4689V10.8118H11.9558L11.9595 10.4726L11.6166 10.4689ZM8.86301 3.35676C8.86301 4.08582 8.57127 4.74606 8.09739 5.22825L8.58645 5.7089C9.18125 5.10368 9.54873 4.27279 9.54873 3.35676H8.86301ZM6.19286 0.686607C7.66755 0.686607 8.86301 1.88207 8.86301 3.35676H9.54873C9.54873 1.50336 8.04626 0.000892848 6.19286 0.000892848V0.686607ZM3.52271 3.35676C3.52271 1.88207 4.71818 0.686607 6.19286 0.686607V0.000892848C4.33947 0.000892848 2.837 1.50337 2.837 3.35676H3.52271ZM4.321 5.26093C3.82788 4.77611 3.52271 4.10239 3.52271 3.35676H2.837C2.837 4.2937 3.22142 5.14148 3.84027 5.7499L4.321 5.26093ZM3.94157 5.19203C1.94291 6.07891 0.547768 8.08117 0.547768 10.41H1.23348C1.23348 8.36203 2.45984 6.59972 4.2197 5.8188L3.94157 5.19203ZM0.547768 10.41C0.547768 10.4309 0.54788 10.4518 0.548104 10.4726L1.23378 10.4652C1.23358 10.4469 1.23348 10.4285 1.23348 10.41H0.547768ZM0.890942 10.8118H11.6166V10.1261H0.890942V10.8118ZM11.9595 10.4726C11.9597 10.4518 11.9598 10.4309 11.9598 10.41H11.2741C11.2741 10.4285 11.274 10.4469 11.2738 10.4652L11.9595 10.4726ZM11.9598 10.41C11.9598 8.04621 10.5225 6.01889 8.47552 5.15282L8.20832 5.78433C10.0106 6.54687 11.2741 8.33129 11.2741 10.41H11.9598Z"
        fill="#16114F"
        mask="url(#path-1-inside-1_62_41709)"
      />
    </g>
    <g filter="url(#filter1_d_62_41709)">
      <mask id="path-3-inside-2_62_41709" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.4981 5.46853C18.0324 4.92482 18.362 4.17928 18.362 3.35676C18.362 1.69272 17.013 0.34375 15.3489 0.34375C13.6849 0.34375 12.3359 1.69272 12.3359 3.35676C12.3359 4.19807 12.6808 4.95884 13.2368 5.50547C11.3576 6.33939 10.0469 8.22164 10.0469 10.41C10.0469 10.4297 10.047 10.4493 10.0472 10.4689H20.7729C20.7731 10.4493 20.7732 10.4297 20.7732 10.41C20.7732 8.18871 19.4227 6.2828 17.4981 5.46853Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4981 5.46853C18.0324 4.92482 18.362 4.17928 18.362 3.35676C18.362 1.69272 17.013 0.34375 15.3489 0.34375C13.6849 0.34375 12.3359 1.69272 12.3359 3.35676C12.3359 4.19807 12.6808 4.95884 13.2368 5.50547C11.3576 6.33939 10.0469 8.22164 10.0469 10.41C10.0469 10.4297 10.047 10.4493 10.0472 10.4689H20.7729C20.7731 10.4493 20.7732 10.4297 20.7732 10.41C20.7732 8.18871 19.4227 6.2828 17.4981 5.46853Z"
        fill="currentColor"
      />
      <path
        d="M17.4981 5.46853L17.2535 5.22821L16.9001 5.58783L17.3645 5.78429L17.4981 5.46853ZM13.2368 5.50547L13.3758 5.81885L13.8366 5.61438L13.4771 5.26097L13.2368 5.50547ZM10.0472 10.4689L9.70435 10.4726L9.708 10.8118H10.0472V10.4689ZM20.7729 10.4689V10.8118H21.1121L21.1157 10.4726L20.7729 10.4689ZM18.0191 3.35676C18.0191 4.0858 17.7274 4.74602 17.2535 5.22821L17.7426 5.70884C18.3374 5.10363 18.7048 4.27276 18.7048 3.35676H18.0191ZM15.3489 0.686607C16.8236 0.686607 18.0191 1.88207 18.0191 3.35676H18.7048C18.7048 1.50337 17.2023 0.000892848 15.3489 0.000892848V0.686607ZM12.6788 3.35676C12.6788 1.88207 13.8743 0.686607 15.3489 0.686607V0.000892848C13.4956 0.000892848 11.9931 1.50337 11.9931 3.35676H12.6788ZM13.4771 5.26097C12.984 4.77616 12.6788 4.10241 12.6788 3.35676H11.9931C11.9931 4.29373 12.3775 5.14153 12.9964 5.74996L13.4771 5.26097ZM13.0977 5.19208C11.0991 6.07899 9.70402 8.08121 9.70402 10.41H10.3897C10.3897 8.36207 11.616 6.59979 13.3758 5.81885L13.0977 5.19208ZM9.70402 10.41C9.70402 10.4309 9.70413 10.4518 9.70435 10.4726L10.39 10.4652C10.3898 10.4469 10.3897 10.4285 10.3897 10.41H9.70402ZM10.0472 10.8118H20.7729V10.1261H10.0472V10.8118ZM21.1157 10.4726C21.1159 10.4518 21.116 10.4309 21.116 10.41H20.4303C20.4303 10.4285 20.4302 10.4469 20.43 10.4652L21.1157 10.4726ZM21.116 10.41C21.116 8.04617 19.6787 6.01881 17.6316 5.15276L17.3645 5.78429C19.1668 6.5468 20.4303 8.33125 20.4303 10.41H21.116Z"
        fill="#16114F"
        mask="url(#path-3-inside-2_62_41709)"
      />
    </g>
    <g filter="url(#filter2_d_62_41709)">
      <mask id="path-5-inside-3_62_41709" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.9253 6.9161C13.4564 6.37288 13.7837 5.62961 13.7837 4.80988C13.7837 3.14584 12.4347 1.79688 10.7707 1.79688C9.10667 1.79688 7.7577 3.14584 7.7577 4.80988C7.7577 5.64845 8.10027 6.40701 8.65311 6.95324C6.77687 7.78841 5.46875 9.66911 5.46875 11.8553C5.46875 11.875 5.46886 11.8946 5.46907 11.9142H16.1947C16.195 11.8946 16.1951 11.875 16.1951 11.8553C16.1951 9.63607 14.8471 7.73164 12.9253 6.9161Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9253 6.9161C13.4564 6.37288 13.7837 5.62961 13.7837 4.80988C13.7837 3.14584 12.4347 1.79688 10.7707 1.79688C9.10667 1.79688 7.7577 3.14584 7.7577 4.80988C7.7577 5.64845 8.10027 6.40701 8.65311 6.95324C6.77687 7.78841 5.46875 9.66911 5.46875 11.8553C5.46875 11.875 5.46886 11.8946 5.46907 11.9142H16.1947C16.195 11.8946 16.1951 11.875 16.1951 11.8553C16.1951 9.63607 14.8471 7.73164 12.9253 6.9161Z"
        fill="currentColor"
      />
      <path
        d="M12.9253 6.9161L12.6801 6.67641L12.329 7.03552L12.7913 7.23171L12.9253 6.9161ZM8.65311 6.95324L8.79253 7.26647L9.25127 7.06227L8.89408 6.70935L8.65311 6.95324ZM5.46907 11.9142L5.12623 11.9179L5.12988 12.2571H5.46907V11.9142ZM16.1947 11.9142V12.2571H16.5339L16.5376 11.9179L16.1947 11.9142ZM13.4409 4.80988C13.4409 5.53645 13.1511 6.19466 12.6801 6.67641L13.1704 7.15579C13.7616 6.5511 14.1266 5.72277 14.1266 4.80988H13.4409ZM10.7707 2.13973C12.2454 2.13973 13.4409 3.3352 13.4409 4.80988H14.1266C14.1266 2.95649 12.6241 1.45402 10.7707 1.45402V2.13973ZM8.10056 4.80988C8.10056 3.3352 9.29603 2.13973 10.7707 2.13973V1.45402C8.91732 1.45402 7.41484 2.95649 7.41484 4.80988H8.10056ZM8.89408 6.70935C8.40376 6.22489 8.10056 5.55311 8.10056 4.80988H7.41484C7.41484 5.74379 7.79678 6.58913 8.41213 7.19713L8.89408 6.70935ZM8.51368 6.64001C6.51824 7.52824 5.12589 9.52882 5.12589 11.8553H5.81161C5.81161 9.8094 7.03551 8.04857 8.79253 7.26647L8.51368 6.64001ZM5.12589 11.8553C5.12589 11.8762 5.12601 11.8971 5.12623 11.9179L5.8119 11.9105C5.81171 11.8922 5.81161 11.8738 5.81161 11.8553H5.12589ZM5.46907 12.2571H16.1947V11.5714H5.46907V12.2571ZM16.5376 11.9179C16.5378 11.8971 16.5379 11.8762 16.5379 11.8553H15.8522C15.8522 11.8738 15.8521 11.8922 15.8519 11.9106L16.5376 11.9179ZM16.5379 11.8553C16.5379 9.49366 15.1032 7.46786 13.0592 6.60048L12.7913 7.23171C14.591 7.99541 15.8522 9.77848 15.8522 11.8553H16.5379Z"
        fill="#16114F"
        mask="url(#path-5-inside-3_62_41709)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_62_41709"
        x="0.890625"
        y="0.34375"
        width="10.7266"
        height="12.4051"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.28" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_62_41709"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_62_41709"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_62_41709"
        x="10.0469"
        y="0.34375"
        width="10.7266"
        height="12.4051"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.28" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_62_41709"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_62_41709"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_62_41709"
        x="5.46875"
        y="1.79688"
        width="10.7266"
        height="12.3973"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.28" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_62_41709"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_62_41709"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
