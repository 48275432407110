<template>
  <div class="container absolute z-40 h-full w-full scale-[1.2] md:scale-100">
    <ClientOnly>
      <LottieAnimation
        ref="pigLaser"
        class="absolute -top-10 z-10 h-full w-full opacity-0 md:top-0"
        loop
        :autoplay="false"
        :src="animationUrl"
        @loaded="onLoaded"
      />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import type { AnimationItem } from "lottie-web";
import { useElementVisibility } from "@vueuse/core";

const pigLaser = ref();

const loaded = ref(0);
const canBeAnimated = ref(false);
const animation = ref<AnimationItem | null>(null);
const animationUrl = ref("/lottie/hero_animation.json");

const isVisible = useElementVisibility(pigLaser, { threshold: 0.5 });

onBeforeMount(() => {
  if (window.innerWidth < 768) {
    animationUrl.value = "/lottie/hero_animation_mobile.json";
  }
});

onMounted(() => {
  setTimeout(() => {
    canBeAnimated.value = true;
  }, 2400);
});

watch([loaded, canBeAnimated], () => {
  if (loaded.value === 1 && canBeAnimated.value) {
    animate();
  }
});

watch(isVisible, (value) => {
  if (!value) {
    animation.value?.pause();
  }

  if (value && loaded.value === 1 && canBeAnimated.value) {
    animation.value?.play();
  }
});

const onLoaded = (anim: AnimationItem) => {
  animation.value = anim;
  loaded.value = 1;
};

const pigLaserMotion = useMotion(pigLaser, {
  initial: {
    opacity: 0,
  },
});

const animate = () => {
  if (!animation.value) return;

  animation.value.play();

  pigLaserMotion.apply({
    opacity: 1,
  });
};
</script>
