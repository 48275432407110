<template>
  <ClientOnly>
    <div
      ref="countdown"
      class="absolute bottom-40 right-0 z-30 -translate-x-1/2 opacity-0 md:block lg:left-1/2 lg:right-auto lg:top-0"
    >
      <div
        class="relative scale-75 lg:-left-[300px] lg:-translate-x-1/2 lg:scale-100"
      >
        <div
          class="text-shadow text-center font-Kontesa text-[34px] font-bold text-yellow md:text-left md:text-[38px]"
        >
          <span v-if="days > 0">{{ zeroPad(days) }}:</span
          ><span>{{ zeroPad(hours) }}</span
          >:<span>{{ zeroPad(minutes) }}</span
          >:<span>{{ zeroPad(seconds) }}</span>
        </div>
        <p class="-mt-2 mb-2 whitespace-nowrap font-medium text-navy">
          COUNTDOWN TO NEXT BLAST!
        </p>

        <div
          class="border-with-shadow relative h-8 overflow-hidden rounded-lg bg-white p-[2px]"
        >
          <div class="relative h-full overflow-hidden rounded-md">
            <!-- Active -->
            <div
              class="absolute left-0 top-0 z-10 flex h-full w-full gap-[2px]"
              :style="{
                clipPath: `inset(0 ${100 - percentageProgress}% 0 0)`,
              }"
            >
              <div
                v-for="i in 12"
                :key="i"
                class="flex h-full w-auto flex-1 bg-[#00E96B]"
              />
            </div>

            <!-- Inactive -->
            <div class="absolute left-0 top-0 flex h-full w-full gap-[2px]">
              <div
                v-for="i in 12"
                :key="i"
                class="flex h-full w-auto flex-1 bg-purple-low"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import dayjs from "dayjs";
import { useTimer } from "~/composables/useTimer";

const countdown = ref();
const countdownMotion = useMotion(countdown, {
  initial: { opacity: 0, y: 20 },
});

onMounted(() => {
  setTimeout(() => {
    countdownMotion.apply({
      opacity: 1,
      y: 0,
    });
  }, 2200);
});
const { days, hours, minutes, seconds, percentageProgress, zeroPad } = useTimer(
  dayjs().endOf("month").toDate(),
);
</script>
