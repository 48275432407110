<template>
  <div class="px-4 pt-3 lg:py-14">
    <div class="container mx-auto rounded-[22px] bg-navy">
      <LandingPageSkyBgSolid
        ref="wrapper"
        class="container relative z-0 mx-auto rounded-3xl border-[3px] border-navy pb-12 pt-10 shadow-[3px_3px_0px_#16114F] lg:pb-0"
      >
        <div class="relative flex grow flex-col gap-4 lg:gap-16">
          <div class="flex flex-col items-center gap-2 px-4 lg:gap-[19px]">
            <span
              class="text-center font-Kontesa text-h3-mobile font-bold uppercase text-navy md:text-h3"
              >Why Dollar <br />
              Donation Club?</span
            >

            <p
              class="max-w-[42ch] text-center text-p1 font-bold uppercase text-navy md:text-h5"
            >
              This is the future of giving
            </p>
          </div>

          <div ref="container">
            <div
              class="no-scrollbar relative flex items-end justify-center overflow-x-auto overflow-y-hidden md:px-[23%] lg:justify-center lg:px-[15%] lg:pb-[69px]"
            >
              <div
                class="mb-8 flex shrink-0 flex-col rounded-l-[9px] border border-r-0 border-navy bg-white lg:border-2"
              >
                <div
                  class="flex h-[38px] items-center justify-center border-b border-navy font-Kontesa text-h3-mobile font-bold text-navy md:text-h3 lg:h-[76px] lg:border-b-2"
                >
                  Benefits
                </div>

                <div
                  v-for="(item, itemIdx) in benefits"
                  :key="item.text"
                  class="mx-5 flex h-[29px] items-center gap-4 border-b border-navy border-opacity-30 last:border-none lg:mx-4 lg:h-16 lg:border-b-2 lg:pr-8"
                >
                  <div
                    :id="item.icon + '-' + itemIdx"
                    class="hidden h-9 w-9 shrink-0 scale-0 items-center justify-center rounded-sm text-purple shadow-sm md:block lg:flex"
                  >
                    <IconsCheckmarkStroke
                      v-if="item.icon === 'check'"
                      class="w-4"
                    />

                    <IconsLensStroke v-if="item.icon === 'lens'" class="w-4" />

                    <IconsGraphStroke
                      v-if="item.icon === 'graph'"
                      class="w-4"
                    />

                    <IconsGroupStroke
                      v-if="item.icon === 'group'"
                      class="w-5"
                    />
                  </div>
                  <div class="md:min-w-[209px]">
                    <span
                      v-if="item.textVisible"
                      :id="'span-' + item.icon + '-' + itemIdx"
                      class="text-[10px] font-bold -tracking-[0.01em] text-navy lg:ml-0 lg:text-h5"
                    >
                      <AnimationScale
                        v-for="(char, index) in Array.from(item.text)"
                        :key="`${item.icon}-${index}`"
                        :char="{
                          value: char,
                          empty: char === ' ' ? true : false,
                          space: index > 0 ? -0.01 : 0,
                        }"
                        :speed="index * speed"
                        :name="item.icon + itemIdx + '-char-' + index"
                        :delay="delayValue"
                        :init="init"
                        play-once
                      >
                        {{ char }}
                      </AnimationScale>
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="mb-8 border border-r-0 border-navy bg-gradient-to-t from-white/0 to-white lg:border-b-2 lg:border-t-2"
              >
                <div
                  class="flex h-[38px] items-center justify-center border-b border-navy px-1 text-center text-[10px] font-bold uppercase text-navy md:text-h5 lg:h-[76px] lg:border-b-2 lg:px-6"
                >
                  Current <br />
                  Philanthropy
                </div>

                <div
                  v-for="(item, index) in benefits"
                  :key="item.text"
                  class="mx-2 flex h-[29px] items-center justify-center gap-4 border-b border-navy border-opacity-30 last:border-none lg:mx-4 lg:h-16 lg:border-b-2"
                >
                  <IconsCloseStroke
                    :id="'icon-close-' + index"
                    class="w-3 scale-0 text-error lg:w-7"
                  />
                </div>
              </div>

              <div
                class="rounded-[13px] border border-navy bg-gradient-to-t from-white to-nature pb-4 lg:rounded-[30px] lg:border-2 lg:pb-8"
              >
                <div
                  class="mx-4 border-b border-navy px-3 pb-2 pt-4 text-center lg:px-8 lg:pb-6 lg:pt-8"
                >
                  <div
                    class="relative flex h-[52px] w-[52px] items-center justify-center rounded-full bg-gradient-to-t from-white from-40% to-white/0 lg:h-[130px] lg:w-[130px]"
                  >
                    <NuxtImg
                      src="/images/logo-icon.png"
                      :width="86"
                      :height="86"
                      alt="logo"
                      class="w-[37px] -rotate-12 lg:w-[84px]"
                    />

                    <div
                      class="text-shadow absolute left-1/2 top-0 hidden -translate-x-1/2 -translate-y-1/2 font-Kontesa text-h3 font-extrabold text-white lg:block"
                    >
                      DDC
                    </div>
                  </div>
                </div>

                <div
                  v-for="(item, index) in benefits"
                  :key="item.text"
                  class="mx-4 flex h-[29px] items-center justify-center gap-4 border-b border-navy last:border-none lg:h-16"
                >
                  <IconsCheckmarkStroke
                    :id="'icon-check-' + index"
                    class="h-[11px] w-[15px] scale-0 text-success lg:h-[27px] lg:w-[35px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <NuxtImg
          v-motion
          src="/images/cloud.png"
          sizes="60vw md:50vw"
          alt="bg clound 1"
          class="absolute right-0 top-4 -z-10 w-[169px] lg:w-[332px]"
          :initial="{
            transform: 'translateX(55%)',
          }"
          :visible="{
            transform: 'translateX(33.33%)',
          }"
        />

        <NuxtImg
          v-motion
          src="/images/cloud.png"
          sizes="60vw md:50vw"
          alt="bg clound 1"
          class="absolute bottom-6 left-0 -z-10 w-[169px] lg:w-[400px]"
          :initial="{
            transform: 'translateX(-70%)',
          }"
          :visible="{
            transform: 'translateX(-50%)',
          }"
        />

        <NuxtImg
          src="/images/cloud.png"
          sizes="60vw md:50vw"
          alt="bg clound 1"
          class="absolute bottom-0 left-1/2 z-10 hidden w-[359px] translate-y-2/3 md:block"
        />
      </LandingPageSkyBgSolid>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useMotion } from "@vueuse/motion";
import type { MotionVariants } from "@vueuse/motion";

const init = true;
const speed = 15;
const delayValue = 200;

const benefits = ref([
  {
    icon: "lens",
    text: "Expert Vetted Projects",
    textVisible: false,
  },
  {
    icon: "check",
    text: "Transparency",
    textVisible: false,
  },
  {
    icon: "graph",
    text: "Measurable Results",
    textVisible: false,
  },
  {
    icon: "check",
    text: "Proof of Impact",
    textVisible: false,
  },
  {
    icon: "check",
    text: "Outcome based",
    textVisible: false,
  },
  {
    icon: "group",
    text: "Unified Giving",
    textVisible: false,
  },
]);

const container = ref<HTMLElement | null>(null);
const hasAnimationRun = ref(false);

const executeWithDelay = async (func: () => void, delay: number) => {
  await new Promise((resolve) => {
    setTimeout(async () => {
      await func();
      resolve(true);
    }, delay);
  });
};

const handleAnimation = async () => {
  if (hasAnimationRun.value) return;

  for (const [index, item] of benefits.value.entries()) {
    const icon = document.querySelector(`#${item.icon}-${index}`);
    const iconClose = document.querySelector(`#icon-close-${index}`);
    const iconCheck = document.querySelector(`#icon-check-${index}`);
    const element = document.querySelector(`#span-${item.icon}-${index}`);

    const transition = {
      type: "spring",
      stiffness: 500,
      damping: 30,
      mass: 3,
    };
    const iconVariants: MotionVariants<string> = {
      initial: { scale: 0 },
      visible: { scale: 1, transition },
    };
    const elementVariants: MotionVariants<string> = {
      initial: { opacity: 0 },
      visible: { opacity: 1, transition },
    };

    const iconRef = ref(icon);
    const iconCloseRef = ref(iconClose);
    const iconCheckRef = ref(iconCheck);
    const elementRef = ref(element);

    await executeWithDelay(async () => {
      if (icon) useMotion(iconRef as MaybeRef<any>, iconVariants);
      benefits.value[index].textVisible = true;
    }, delayValue);

    await executeWithDelay(async () => {
      if (element) useMotion(elementRef as MaybeRef<any>, elementVariants);
    }, delayValue);

    await executeWithDelay(async () => {
      if (iconClose) useMotion(iconCloseRef as MaybeRef<any>, iconVariants);
    }, delayValue);

    await executeWithDelay(async () => {
      if (iconCheck) useMotion(iconCheckRef as MaybeRef<any>, iconVariants);
    }, delayValue);

    iconVariants.initial = { scale: 1 };
    elementVariants.initial = { opacity: 1 };
    hasAnimationRun.value = true;
  }
};

useIntersectionObserver(
  container,
  ([{ isIntersecting }]) => {
    if (isIntersecting) handleAnimation();
  },
  { threshold: 1 },
);
</script>
