import { featuresList } from "~/components/LandingPage/Features/featuresList";

const CONTAINER_SIZE = 8000;
const ELEMENT_OFFSET = 100;
const ELEMENT_OFFSET_MOBILE = 50;

/*
Handle scroll hijacking
*/
export const useElementPosition = (elementRef: Ref<HTMLDivElement>) => {
  const containerSize = ref(CONTAINER_SIZE);
  const positionTopFromWindow = ref(0);
  const shouldBeFixed = ref(false);
  const scroll = useWindowScroll();
  const isMobile = useIsMobile();
  const isScrollLockedValue = ref<number | null>(null);

  onMounted(() => {
    handleElementPosition();
    handleVisibility();
    handleScrollLock();

    setTimeout(handleElementPosition, 500);
  });

  const relativePosition = computed(() => {
    return positionTopFromWindow.value - scroll.y.value;
  });

  const elementOffset = computed(() => {
    return isMobile.value ? ELEMENT_OFFSET_MOBILE : ELEMENT_OFFSET;
  });

  watch(relativePosition, () => {
    handleVisibility();
  });

  /* 
  This function is responsible for calculating the top position of the element relative to the window(not viewport)
  */
  const handleElementPosition = () => {
    const element = elementRef.value;
    if (!element) return;

    const topFromViewPort = element.getBoundingClientRect().top;
    const scrollTop = scroll.y.value;

    const top = topFromViewPort + scrollTop;

    positionTopFromWindow.value = top;

    console.log("positionTopFromWindow", positionTopFromWindow.value);
  };

  /* 
  This function is responsible for setting the visibility of the element
  */
  const handleVisibility = () => {
    shouldBeFixed.value = relativePosition.value < elementOffset.value;
  };

  const handleScrollLock = () => {
    window.addEventListener(
      "scroll",
      (ev) => {
        if (isScrollLockedValue.value) {
          window.scrollTo({
            top: isScrollLockedValue.value,
            behavior: "instant",
          });
          ev.preventDefault();
        }
      },
      { passive: false },
    );
  };

  const percentage = computed({
    get: () => {
      if (containerSize.value === 0) return 0;

      const totalSize = containerSize.value - window.innerHeight;

      const perc = (relativePosition.value / totalSize) * 100;
      if (perc > 0) return 0;

      return Math.abs(perc);
    },
    set: (_newPercentage: number) => {
      // const spaceSize = containerSize.value - window.innerHeight;
      // const percFrac = newPercentage / 100;
      // const relativeNewScroll = percFrac * spaceSize;
      // const newScroll = positionTopFromWindow.value + relativeNewScroll + 10;
      // if (newPercentage <= 0.1) return;
      // // isScrollLockedValue.value = newScroll;
      // // setTimeout(() => {
      // //   isScrollLockedValue.value = null;
      // // }, 500);
    },
  });

  const scrollToStep = (index: number) => {
    const totalSize = containerSize.value - window.innerHeight;
    const relativeOffset = index * (totalSize / featuresList.length);

    window.scrollTo({
      top: relativeOffset + positionTopFromWindow.value + 100,
      behavior: "instant",
    });
  };

  return {
    scrollToStep,
    percentage,
    shouldBeFixed,
    containerSize,
    relativePosition,
  };
};
