<template>
  <UiScrollingMarquee :speed="50">
    <div v-for="n in 5" :key="n" class="mx-4 inline-block">
      <div class="flex items-center gap-2">
        <NuxtImg
          :src="'/images/icons/' + icon + '.png'"
          :width="36"
          :height="36"
          :alt="'turtle scroll icon ' + n"
        />

        <IconsTriangle class="text-success" />

        <span class="font-SubwayTicker text-p1 text-success"
          >{{ Math.round(lastMonthImpact) }} in {{ currentMonthName }}</span
        >
      </div>
    </div>
  </UiScrollingMarquee>
</template>

<script setup lang="ts">
interface CampaignsMarqueeProps {
  lastMonthImpact: number;
  icon: string;
}

const { lastMonthImpact } = defineProps<CampaignsMarqueeProps>();

const currentMonthName = computed(() => {
  return new Date().toLocaleString("default", { month: "long" });
});
</script>
