<template>
  <div
    class="container relative mx-auto flex flex-col gap-[38px] px-4 pb-40 pt-10 md:gap-20 md:pt-[90px]"
  >
    <Headline
      class="text-shadow text-center font-Kontesa text-[10vw] leading-none md:text-h2"
    >
      Our Big Vision
    </Headline>

    <div class="flex flex-col gap-4 lg:flex-row">
      <div
        class="relative h-[508px] overflow-hidden rounded-[20px] border-2 border-navy bg-navy shadow-[2px_2px_0px_#16114F] lg:basis-4/6"
      >
        <NuxtImg
          src="/images/landing-page/our-big-vision/bibi-mobile.png"
          sizes="100vw md:60vw"
          class="absolute top-0 h-full w-full object-cover object-top md:hidden"
          alt="bibi the fundraiser thumbnail"
        />

        <NuxtImg
          src="/images/landing-page/our-big-vision/bibi-desktop.png"
          sizes="100vw md:60vw"
          class="absolute top-0 hidden h-full w-full object-cover md:block"
          alt="bibi the fundraiser thumbnail"
        />

        <Button
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 uppercase"
          variant="secondary"
          @click="playVideo"
        >
          Play video
        </Button>

        <VideoPlayerModal id="552662393?h=8be6600544" ref="video" />
      </div>

      <div
        class="mx-auto w-fit overflow-hidden rounded-[20px] border-2 border-navy bg-navy shadow-[2px_2px_0px_#16114F] lg:h-[508px]"
      >
        <NuxtImg
          src="/images/landing-page/magazine.jpeg"
          alt="time magazine"
          sizes="100vw md:50vw lg:30vw"
          class="h-full object-cover"
        />
      </div>
    </div>

    <div class="flex flex-col gap-4 pt-10">
      <div
        class="text-shadow text-center font-Kontesa text-h1-mobile font-extrabold text-white md:text-h2"
      >
        <span class="text-yellow">1 billion people</span> giving
        <span class="text-yellow">$1/month</span>, creating the world's first
        collective <span class="text-yellow">super-philanthropist</span>
      </div>

      <p class="text-center text-p1 font-bold text-navy md:text-h5">
        GOT A DOLLAR FOR THE PLANET?
      </p>

      <div class="flex flex-col pt-14 md:items-center">
        <Button variant="primary" class="md:w-[242px]" @click="redirect"
          >Give $1</Button
        >
      </div>
    </div>

    <NuxtImg
      v-motion
      src="/images/cloud.png"
      sizes="60vw md:50vw"
      alt="bg clound"
      class="absolute bottom-0 left-0 w-[320px] md:w-[459px] lg:-bottom-28 lg:w-[659px]"
      :initial="{
        transform: 'translateX(-100%)',
      }"
      :visible="{
        transform: 'translateX(-60%)',
      }"
    />
  </div>
</template>

<script setup lang="ts">
import VideoPlayerModal from "~/components/VideoPlayer/Modal.vue";

const { Routes } = useRouteUtils();
const router = useRouter();
const redirect = () => router.push(Routes.SIGN_UP);

const video = ref<InstanceType<typeof VideoPlayerModal> | null>(null);
const playVideo = () => {
  video.value?.play();
};
</script>
