<template>
  <div class="flex items-center justify-between gap-3">
    <div class="flex items-center gap-3 @xl:order-1 @xl:shrink">
      <Box
        class="grid h-[56px] w-[56px] place-content-center"
        :class="iconBgColorByType"
      >
        <NuxtImg :src="imageSrcByType" width="148" class="h-[30px] w-[30px]" />
      </Box>
      <div class="flex-1 space-y-1">
        <div
          class="font-Kontesa text-[21px] font-bold leading-none tracking-tight"
        >
          {{ numberFormatter.format(amount) }}
        </div>
        <div
          class="font-Aeonik text-p2 font-normal leading-none tracking-tight"
        >
          {{ unitOfImpact }}
        </div>
      </div>
    </div>

    <ProfileImpactProgressBar
      class="w-full max-w-[316px]"
      :progress="currentProgress"
      :level="currentLevel"
    >
    </ProfileImpactProgressBar>
  </div>
</template>

<script lang="ts" setup>
type ImpactType = "trees" | "ocean" | "education" | "family";

interface impactStatProps {
  type: ImpactType;
  amount: number;
  currentProgress: number;
  currentLevel: number;
}

const props = defineProps<impactStatProps>();

const unitOfImpact = computed(() => {
  switch (props.type) {
    case "trees":
      return "Trees Planted";
    case "ocean":
      return "Lbs of Plastic Removed";
    case "education":
      return "Days of School Funded";
    case "family":
      return "Members";
    default:
      return "";
  }
});

const imageSrcByType = computed(() => {
  switch (props.type) {
    case "trees":
      return "/images/icons/tree.png";
    case "ocean":
      return "/images/icons/water-wave.png";
    case "education":
      return "/images/icons/book.png";
    default:
      return "";
  }
});

const iconBgColorByType = computed(() => {
  switch (props.type) {
    case "trees":
      return "bg-[#73F3AE]";
    case "ocean":
      return "bg-[#61E5FF]";
    case "education":
      return "bg-[#FFE617]";
    default:
      return "";
  }
});

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  notation: "compact",
  maximumFractionDigits: 2,
});
</script>

<style lang="scss" scoped>
//
</style>
