<template>
  <LandingPageFeaturesCardsRoot class="">
    <div
      class="flex h-full flex-col justify-center gap-10 bg-gradient-to-t from-white to-yellow @container"
    >
      <div
        v-if="previewOpen"
        v-motion
        :initial="{
          opacity: 0,
        }"
        :visible="{
          opacity: 1,
        }"
        class="inset-0 flex flex-col justify-around gap-10 overflow-hidden"
      >
        <div
          class="h-[70px] w-[200%] -translate-x-1/4 -rotate-[25deg] bg-white/20"
        />
        <div
          class="h-[280px] w-[200%] -translate-x-1/4 -rotate-[25deg] bg-white/20"
        />
        <div
          class="h-[70px] w-[200%] -translate-x-1/4 -rotate-[25deg] bg-white/20"
        />
      </div>

      <div
        v-if="previewOpen"
        class="flex w-full items-start justify-between p-12"
      >
        <NuxtImg
          v-motion
          src="/images/emojis/flying-money.png"
          width="120"
          height="120"
          alt="money icon headline"
          :initial="{
            opacity: 0,
            y: -100,
            x: -100,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            x: 0,
            transition: {
              delay: 600,
            },
          }"
        />

        <div
          v-motion
          class="flex flex-col gap-4"
          :initial="{
            opacity: 0,
            y: -100,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 200,
            },
          }"
        >
          <span
            class="text-shadow text-center font-Kontesa text-h2 font-extrabold text-white"
            >Know your donation <br />
            actually does!</span
          >

          <span class="text-center text-p1 font-medium text-navy"
            >That's what we call "Return on Donation"</span
          >
        </div>

        <NuxtImg
          v-motion
          src="/images/emojis/flying-money.png"
          width="120"
          height="120"
          alt="money icon headline"
          :initial="{
            opacity: 0,
            y: -100,
            x: 100,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            x: 0,
            transition: {
              delay: 600,
            },
          }"
        />
      </div>

      <div class="relative flex flex-col items-start justify-center gap-4 px-4">
        <div v-for="index of 4" :key="index">
          <div
            v-if="!previewOpen || index < 2"
            v-motion
            class="flex w-full justify-center gap-6 lg:flex lg:w-[530px]"
            :class="{ hidden: index > 2 }"
            :initial="{
              opacity: 0,
              x: 100,
            }"
            :visible="{
              opacity: 1,
              x: 0,
              transition: {
                delay: index * 250,
              },
            }"
            :leave="{
              opacity: 0,
              x: 100,
            }"
          >
            <div
              class="flex aspect-square w-[80px] flex-col items-center justify-center gap-2 rounded-2xl border border-navy bg-main-bg p-6 shadow-[2px_2px_0px_#16114F] lg:w-24"
              :class="{
                'bg-ocean': index === 1,
                'bg-yellow': index === 2,
                'bg-nature': index === 3,
                'bg-orange': index === 4,
              }"
            >
              <span class="text-h3 font-black text-navy">$1</span>
            </div>

            <div
              class="flex w-[63px] shrink-0 flex-col items-center justify-center gap-3"
            >
              <div
                v-for="i of 2"
                :key="i"
                class="h-[17px] w-full rounded-sm bg-white shadow-md"
              />
            </div>

            <div
              class="flex w-full max-w-[329px] flex-col items-center justify-center gap-2 rounded-2xl border border-navy bg-gradient-to-tr from-white p-[6px] shadow-[2px_2px_0px_#16114F] lg:flex-row lg:p-2"
              :class="{
                'to-ocean': index === 1,
                'bg-yellow': index === 2,
                'bg-nature': index === 3,
                'bg-orange': index === 4,
              }"
            >
              <NuxtImg
                :src="imageSrcByType(index)"
                alt="campaign cover"
                width="80"
                height="80"
                class="h-[76px] w-full rounded-xl border border-navy object-cover lg:h-[80px] lg:w-auto"
              />

              <span
                class="w-full text-center text-p2 font-bold uppercase text-navy"
                >{{ textDescriptionByType(index) }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </LandingPageFeaturesCardsRoot>
</template>

<script setup lang="ts">
const previewOpen = ref(false);

const imageSrcByType = (index: number) => {
  switch (index) {
    case 1:
      return "/images/landing-page/scroll-animation/step-1/plastic-fischer.png";
    case 2:
      return "/images/landing-page/scroll-animation/step-1/child.png";
    case 3:
      return "/images/landing-page/scroll-animation/step-1/trees-card.jpeg";
    case 4:
      return "/images/landing-page/scroll-animation/step-1/ocean-plastic.png";
    default:
      return "";
  }
};

const textDescriptionByType = (index: number) => {
  switch (index) {
    case 1:
      return "Removes 2.2 lbs of ocean-bound plastic";
    case 2:
      return "2 days of education for 1 child";
    case 3:
      return "Plants 2.86 trees";
    case 4:
      return "Removes 1/3 lbs of ocean plastic";
    default:
      return "";
  }
};
</script>
