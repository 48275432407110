<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_62_41762)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2373 16L2 6.76268L6.76268 2L16 11.2373L25.2373 2L30 6.76268L20.7627 16L30 25.2373L25.2373 30L16 20.7627L6.76268 30L2 25.2373L11.2373 16Z"
        fill="currentColor"
      />
      <path
        d="M10.5302 16L1.64645 24.8838L1.29289 25.2373L1.64645 25.5909L6.40913 30.3536L6.76268 30.7071L7.11624 30.3536L16 21.4698L24.8838 30.3536L25.2373 30.7071L25.5909 30.3536L30.3536 25.5909L30.7071 25.2373L30.3536 24.8838L21.4698 16L30.3536 7.11624L30.7071 6.76268L30.3536 6.40913L25.5909 1.64645L25.2373 1.29289L24.8838 1.64645L16 10.5302L7.11624 1.64645L6.76268 1.29289L6.40913 1.64645L1.64645 6.40913L1.29289 6.76268L1.64645 7.11624L10.5302 16Z"
        stroke="#16114F"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_62_41762"
        x="0.585938"
        y="0.585815"
        width="33.8281"
        height="33.8284"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3" dy="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0862745 0 0 0 0 0.0666667 0 0 0 0 0.309804 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_62_41762"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_62_41762"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CustomCloseStrokeIcon",
};
</script>
