<template>
  <div class="flex flex-col">
    <LandingPageHero />

    <LandingPageBannerSimple />

    <LandingPageProjects />

    <div class="mt-12 lg:mt-16">
      <LandingPageFeatures v-if="!isMobile" />
      <LandingPageFeaturesMobile v-else />
    </div>

    <LandingPageOurImpact />

    <div class="bg-gradient-to-b from-transparent via-[#7CEAFF] to-transparent">
      <div class="mb-10 mt-20">
        <Headline
          class="relative z-10 mb-[60px] text-center text-h1-mobile font-black md:mb-0 md:text-h1"
          >Leaderb<span class="-tracking-[0.06em]">o</span>ard</Headline
        >
        <p
          class="mb-16 mt-10 hidden text-center text-h5-mobile font-bold uppercase text-navy lg:block lg:text-h5"
        >
          Compete to make the most impact
        </p>
      </div>
      <LandingPageLeaderboard />
    </div>

    <LandingPageWhyDDC />

    <LandingPageFeatureCampaign />

    <LandingPageOutBigVision />
  </div>
</template>

<script setup lang="ts">
import { useQueryClient } from "@tanstack/vue-query";
import { campaignQueries } from "~/domains/campaigns/queries";
import { landingQueries } from "~/domains/landing/queries";

const meta = {
  title: "Dollar Donation Club",
  description:
    "Join the Dollar Donation Club and make a difference with just $1 a month. Support vetted charities, track your impact, and be part of a global community changing the world one dollar at a time. Sign up now!",
  keywords:
    "dollar donation,monthly giving,charity,philanthropy,microdonations",
};

useSeoMeta({
  ogTitle: meta.title,
  description: meta.description,
  ogDescription: meta.description,
  keywords: meta.keywords,
});

const isMobile = useIsMobile();

const queryClient = useQueryClient();

await Promise.all([
  queryClient.prefetchQuery(landingQueries.marquee()),
  queryClient.prefetchQuery(campaignQueries.list()),
]);
</script>
