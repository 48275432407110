<template>
  <LandingPageFeaturesCardsRoot>
    <div class="card-bg flex w-full flex-col gap-5 px-6 pt-6">
      <div
        v-for="challenge in challenges"
        :key="challenge.id"
        :class="`flex flex-col gap-4 self-start rounded-2xl p-4 shadow-base ${challenge.color}`"
      >
        <div class="flex items-center gap-4">
          <div
            :class="`flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md shadow-base ${
              challenge.status === 'active' ? 'bg-white' : 'bg-none'
            }`"
          >
            <IconsCheckmark v-show="challenge.status === 'active'" />
          </div>

          <span
            class="font-Kontesa text-h4-mobile font-medium text-navy sm:text-h4"
          >
            {{ challenge.title }}
          </span>

          <button
            v-show="challenge.children.length"
            class="ml-auto flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-base"
          >
            <IconsRemove />
          </button>
        </div>

        <img :src="challenge.image" class="w-full" />

        <div>
          <div class="flex flex-col gap-4">
            <div
              v-for="(child, i) in challenge.children"
              :key="i"
              v-motion-slide-bottom
              class="flex flex-col gap-4 rounded-xl p-4 shadow-base"
            >
              <div>
                <div class="flex flex-col gap-1">
                  <div class="flex items-center gap-4">
                    <div
                      class="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md shadow-base"
                    />

                    <span
                      class="text-h5-mobile font-bold uppercase text-navy sm:text-h5"
                    >
                      {{ child.title }}
                    </span>

                    <button
                      v-show="child.children.length"
                      class="ml-auto flex h-8 w-8 items-center justify-center"
                    >
                      <IconsRemove />
                    </button>
                  </div>
                </div>
              </div>

              <div class="w-full border-b border-dashed border-black" />

              <div class="flex flex-col gap-4">
                <div v-for="(grandchild, j) in child.children" :key="j">
                  <div>
                    <div class="flex flex-col gap-1">
                      <div class="flex items-center gap-4">
                        <div
                          class="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md shadow-base"
                          :class="grandchild.live ? 'bg-white' : 'bg-none'"
                        >
                          <IconsCheckmark
                            :class="grandchild.live ? 'block' : 'hidden'"
                          />
                        </div>

                        <span
                          class="text-h5-mobile font-medium text-navy sm:text-h5"
                        >
                          {{ grandchild.title }}
                        </span>
                      </div>

                      <img
                        v-show="grandchild.live"
                        src="~assets/images/humanitys-checklist/live-btn.svg"
                        class="ml-auto w-24"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LandingPageFeaturesCardsRoot>
</template>

<script lang="ts" setup>
const challenges = [
  {
    id: 1,
    color: "green",
    image: "/images/funds/earth-fund.png",
    status: "active",
    title: "The Earth Fund",
    children: [
      {
        id: 2,
        status: "active",
        title: "Plant 1 trillion trees",
        children: [
          {
            id: 3,
            status: "active",
            to: "/trees",
            live: true,
            title:
              "Plant 2.4 million trees in Tanzania, eradicating hunger & poverty for 600 families",
          },
        ],
      },
      {
        id: 4,
        status: "active",
        title: "Protect and regenerate key ecosystems",
        children: [
          {
            id: 3,
            status: "active",
            title: "The Sumatran Rainforest, Asia",
            live: false,
          },
        ],
      },
    ],
  },
];
</script>

<style lang="scss" scoped>
.card-bg {
  background: linear-gradient(249.87deg, #61e5ff 0%, #d0f7ff 100%);
}

.green {
  @apply bg-nature;
}
</style>
