<template>
  <div class="px-4 pt-[60px] lg:pb-[48px] lg:pt-[120px]">
    <div class="container relative mx-auto flex flex-col gap-10">
      <div
        class="animation absolute left-8 top-5 z-10 sm:left-28 sm:top-5 lg:left-44 lg:top-16 xl:left-60"
      >
        <IconsStar class="w-[84px] sm:w-[124px] md:w-full" />
        <span
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-[15deg] text-center font-Kontesa text-h5-mobile font-extrabold text-navy sm:text-h5 md:text-h3"
          >Holy <br />
          crap!</span
        >
      </div>
      <!-- Content box -->
      <LandingPageSkyBgSolid
        class="container mx-auto rounded-[20px] shadow-base"
      >
        <div class="flex w-full flex-col justify-around py-10 md:py-16">
          <div class="mx-auto flex w-fit flex-col gap-8 lg:gap-12">
            <span
              class="text-shadow text-center font-Kontesa text-h1-mobile font-extrabold uppercase text-white sm:text-h2 lg:text-h1"
            >
              look what a <br />
              dollar can do!</span
            >

            <p
              class="text-center text-h5-mobile font-bold uppercase text-navy lg:text-h5"
            >
              Browse our Dollar Projects
            </p>
          </div>

          <div class="py-4 md:pb-[62px] md:pt-16">
            <ProjectsCarousel />
          </div>

          <div class="flex justify-center px-4 md:px-0">
            <Button
              variant="primary"
              class="w-full max-w-[400px]"
              @click="redirect"
              >join with $1/mo now</Button
            >
          </div>
        </div>
      </LandingPageSkyBgSolid>

      <LandingPageSkyBgSolid
        class="container mx-auto rounded-[20px] border border-navy shadow-[3px_3px_0px_#16114F]"
      >
        <div
          class="flex w-full items-start justify-between p-4 lg:items-center lg:p-10"
        >
          <NuxtImg
            src="/images/landing-page/impact-guarantee/shield.png"
            class="h-auto w-[28px] text-purple-medium sm:w-[7vw] lg:w-[98px]"
          />

          <div class="flex flex-col justify-around gap-11 pb-5 lg:pb-0">
            <div class="relative flex flex-col gap-6 lg:gap-4">
              <span
                class="text-shadow text-center font-Kontesa text-[28px] font-extrabold uppercase leading-none -tracking-[0.02em] text-white sm:text-[7vw] lg:text-h2"
              >
                Impact Guarantee</span
              >

              <p
                class="text-center text-h5-mobile font-bold uppercase text-navy lg:text-h5"
              >
                Your donation makes a measurable <br class="hidden lg:block" />
                difference or you get it back.
              </p>
            </div>

            <div class="flex justify-center">
              <button
                class="h-[21px] border-b-2 border-navy font-Aeonik text-[15px] font-bold uppercase -tracking-[0.02em] text-navy"
                @click="redirect"
              >
                Learn more
              </button>
            </div>
          </div>

          <NuxtImg
            src="/images/landing-page/impact-guarantee/shield.png"
            class="h-auto w-[28px] text-purple-medium sm:w-[7vw] lg:w-[98px]"
          />
        </div>
      </LandingPageSkyBgSolid>
    </div>
  </div>
</template>

<script setup lang="ts">
const { Routes } = useRouteUtils();
const router = useRouter();
const redirect = () => router.push(Routes.SIGN_UP);
</script>

<style scoped>
.animation {
  animation: scaling 1s ease-in-out 0s infinite alternate;
}

@keyframes scaling {
  from {
    transform: scale(0.8) translateX(-30%) translateY(-50%) rotate(-12deg);
  }
  to {
    transform: scale(1) translateX(-30%) translateY(-50%) rotate(-12deg);
  }
}
</style>
