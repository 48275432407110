<template>
  <LandingPageFeaturesCardsRoot>
    <div class="flex h-full w-full flex-col gap-7 bg-[#E2DFFF]">
      <div class="z-10 px-11 pt-[40px]">
        <LandingPageScrollAnimationStep6CardCounter v-bind="stat" />
      </div>
      <NuxtImg
        src="/images/landing-page/scroll-animation/step-6/donate-with-others.png"
        width="566"
        height="400"
        class="relative bottom-20 h-[247px] md:bottom-0 md:h-[400px] md:w-full"
      />
    </div>
  </LandingPageFeaturesCardsRoot>
</template>

<script lang="ts" setup>
const stat = {
  campaignId: 3,
  value: 588834,
  unit: null,
  desc: "Trees Planted",
  icon: "tree",
  lastMonthImpact: 100,
};
</script>

<style lang="scss" scoped>
.card-bg {
  background: linear-gradient(179.38deg, #ffffff -10.17%, #9f94ff 99.49%);
}
</style>
