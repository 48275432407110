<template>
  <div
    class="relative z-0 flex overflow-hidden bg-gradient-to-b from-ocean to-white"
  >
    <div
      class="absolute inset-0 -z-10 flex flex-col justify-around gap-10 overflow-hidden"
    >
      <div
        class="h-[70px] w-[200%] -translate-x-1/4 -rotate-[25deg] bg-white/20"
      />
      <div
        class="h-[280px] w-[200%] -translate-x-1/4 -rotate-[25deg] bg-white/20"
      />
      <div
        class="h-[70px] w-[200%] -translate-x-1/4 -rotate-[25deg] bg-white/20"
      />
    </div>

    <div class="flex w-full justify-center overflow-auto">
      <slot />
    </div>
  </div>
</template>
