<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_62_41733)">
      <path
        d="M6.79867 12.4509H6.79841C5.44066 12.452 4.12271 11.9922 3.06013 11.1467C1.99755 10.3012 1.25324 9.11993 0.948962 7.79621C0.644683 6.47249 0.798452 5.08466 1.38511 3.85973C1.97177 2.6348 2.95659 1.64529 4.17846 1.05304C5.40034 0.46079 6.78695 0.300854 8.11149 0.599388C9.43604 0.897922 10.6201 1.63726 11.4701 2.69648C12.32 3.7557 12.7855 5.0721 12.7903 6.43035C12.7952 7.78861 12.3391 9.10831 11.4968 10.1736L11.4022 10.2932L11.51 10.4011L15.1255 14.0178L14.3619 14.7816L10.7466 11.165L10.6383 11.0567L10.5185 11.1521C9.46137 11.9939 8.14987 12.4518 6.79867 12.4509ZM9.52994 2.36264C8.72145 1.82224 7.77092 1.5338 6.79855 1.5338C5.49464 1.5338 4.24414 2.05196 3.32215 2.97427C2.40017 3.89658 1.88221 5.14748 1.88221 6.4518C1.88221 7.42447 2.17054 8.37531 2.71075 9.18407C3.25095 9.99283 4.01878 10.6232 4.91713 10.9954C5.81548 11.3677 6.804 11.4651 7.75769 11.2753C8.71138 11.0855 9.58739 10.6171 10.2749 9.92933C10.9625 9.24153 11.4307 8.36523 11.6204 7.41124C11.8101 6.45725 11.7128 5.46842 11.3407 4.56978C10.9686 3.67114 10.3384 2.90305 9.52994 2.36264Z"
        fill="currentColor"
        stroke="#16114F"
        stroke-width="0.342857"
      />
      <g filter="url(#filter1_b_62_41733)">
        <circle
          cx="6.79256"
          cy="6.44881"
          r="4.96443"
          fill="white"
          fill-opacity="0.02"
        />
        <circle
          cx="6.79256"
          cy="6.44881"
          r="4.84443"
          stroke="#16114F"
          stroke-width="0.24"
        />
      </g>
    </g>
    <line
      x1="3.22989"
      y1="5.03174"
      x2="4.87701"
      y2="3.46"
      stroke="#16114F"
      stroke-width="0.171429"
    />
    <line
      x1="2.96317"
      y1="6.01718"
      x2="5.7065"
      y2="3.30439"
      stroke="#16114F"
      stroke-width="0.171429"
    />
    <defs>
      <filter
        id="filter0_d_62_41733"
        x="0.625"
        y="0.28125"
        width="14.7422"
        height="15.7714"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.02857" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_62_41733"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_62_41733"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_62_41733"
        x="0.456696"
        y="0.112946"
        width="12.6725"
        height="12.6717"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.685714" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_62_41733"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_62_41733"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CustomLensStrokeIcon",
};
</script>
