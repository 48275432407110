<template>
  <div>
    <Headline class="pb-[69px] text-center text-h2">How It Works</Headline>
    <div
      ref="features"
      class="container mx-auto"
      :style="{
        height: `${containerSize}px`,
      }"
    >
      <ClientOnly>
        <div
          class="sticky top-[50px] grid w-full px-4 md:top-[100px] md:grid-cols-2 md:px-0"
        >
          <!-- Features list -->
          <div
            class="relative transition-all duration-500"
            :style="{
              top: `${listTopPosition}px`,
            }"
          >
            <LandingPageFeaturesDescription
              v-for="(feat, index) in featuresList"
              :key="feat.title"
              :title="feat.title"
              :description="feat.description"
              :step="index + 1"
              :active="currentActiveFeature.title === feat.title"
              :percentage-to-next-feature="percentageToNextFeature"
              @click="scrollToStep(index)"
            />
          </div>

          <!-- Feature card -->
          <div
            class="bottom-4 left-0 h-[calc(50dvh)] w-full px-4 md:relative md:bottom-auto md:left-auto md:top-auto md:h-auto md:translate-y-0 md:px-0"
            :class="{
              'fixed transition-all md:block': shouldBeFixed,
            }"
          >
            <Transition
              group
              enter-active-class="relative z-10 left-0 duration-500 ease-out"
              enter-from-class="opacity-0 translate-y-full"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="duration-200 ease-in"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-full"
            >
              <Component
                :is="currentActiveFeature.component"
                v-if="currentActiveFeature"
                :key="currentActiveFeature.title"
              />
            </Transition>
          </div>
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useFeaturesList } from "~/domains/landing/features/composables/useFeaturesList";
import { featuresList } from "./featuresList";
import { useElementPosition } from "~/domains/landing/features/composables/useElementPosition";

const features = ref();

const { scrollToStep, shouldBeFixed, containerSize, percentage } =
  useElementPosition(features);
const { currentActiveFeature, listTopPosition, percentageToNextFeature } =
  useFeaturesList(percentage);
</script>
