<template>
  <div class="relative z-10 flex flex-col pt-4">
    <div>
      <div
        class="text-shadow mb-[0.5px] text-center font-Kontesa text-[54px] font-bold leading-none -tracking-[0.035em] text-pink md:text-h2 lg:mb-0 lg:text-h1"
      >
        <template v-for="(letter, index) in heading1" :key="index">
          <span
            v-if="letter !== ' '"
            v-motion
            class="inline-block origin-bottom scale-0"
            :initial="{
              scale: 0,
            }"
            :enter="{
              scale: 1,
              transition: {
                type: 'spring',
                stiffness: 60,
                damping: 7,
                mass: 0.8,
                delay: 200 + index * 20,
              },
            }"
            >{{ letter }}</span
          >
          <span v-else>{{ " " }}</span>
        </template>
      </div>

      <div
        class="text-shadow -mt-1 mb-4 text-center font-Kontesa text-[36px] font-bold leading-none -tracking-[0.03em] text-yellow md:mt-0 md:text-h3 lg:mb-4 lg:text-h2"
      >
        <template v-for="(letter, index) in heading2" :key="index">
          <span
            v-if="letter !== ' '"
            v-motion
            class="inline-block origin-bottom scale-0"
            :initial="{
              scale: 0,
            }"
            :enter="{
              scale: 1,
              transition: {
                type: 'spring',
                stiffness: 60,
                damping: 7,
                mass: 0.8,
                delay: 1000 + index * 20,
              },
            }"
            >{{ letter }}</span
          >
          <span v-else>{{ " " }}</span>
        </template>
      </div>

      <p
        v-motion
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :enter="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 2000,
          },
        }"
        class="mx-auto max-w-[270px] text-center font-Kontesa text-2xl leading-none -tracking-[0.03em] text-navy opacity-0 md:max-w-full md:text-h4-mobile lg:text-h4"
      >
        $1/mo from 1,000,000 people to solve global challenges
      </p>
    </div>

    <div
      class="relative mt-10 flex h-[400px] w-full flex-col items-center justify-end overflow-hidden pb-10 md:h-[440px]"
    >
      <LandingPageHeroAnimation />
      <LandingPageHeroCountdown />

      <NuxtImg
        src="/images/landing-page/hero-bg.png"
        sizes="100vw md:100vw"
        class="image absolute -bottom-24 h-full w-full object-cover object-top lg:inset-0"
        alt="hero bg"
      />

      <div
        class="absolute inset-0 h-full w-full bg-gradient-to-t from-main-bg to-30% bg-blend-darken"
      />

      <NuxtImg
        src="/images/footer/noise-pattern.png"
        sizes="100vw md:100vw"
        class="mask absolute inset-0 h-full w-full object-cover"
        alt="noise pattern"
      />

      <div class="absolute inset-0 flex items-center justify-center">
        <!-- <Vue3Lottie
            animationLink="/lottie/pig-laser-final.json"
            :height="500"
          /> -->
      </div>

      <Button
        v-motion
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :enter="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 2500,
          },
        }"
        variant="primary"
        class="relative z-40 w-full px-4 opacity-0 md:w-auto md:px-0"
        @click="redirect"
        >join with $1/mo now</Button
      >
    </div>

    <div class="pt-3 lg:pt-6">
      <AnimationCarouselFeed />
    </div>
  </div>
</template>

<script setup lang="ts">
const { Routes } = useRouteUtils();
const router = useRouter();
const redirect = () => router.push(Routes.SIGN_UP);

const heading1 = "the world’s first";
const heading2 = "collective philanthropist";
</script>

<style scoped>
.mask {
  mask-image: linear-gradient(
    355.72deg,
    rgba(0, 0, 0, 0.2) 54.97%,
    rgba(0, 0, 0, 0) 94.67%
  );
  -webkit-mask-image: linear-gradient(
    355.72deg,
    rgba(0, 0, 0, 0.2) 54.97%,
    rgba(0, 0, 0, 0) 94.67%
  );
  filter: invert(1);
}

.image {
  object-position: 50% 105%; /* Horizontal offset 50%, Vertical offset 25% */
  /* visibility: hidden; */
}
</style>
