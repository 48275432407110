<template>
  <div
    class="max-h-[348px] rounded-xl border border-navy bg-white bg-opacity-80 px-4 py-8 shadow-[2px_2px_0px_#16114F]"
  >
    <div class="mb-5 font-Kontesa text-[32px] font-bold leading-none text-navy">
      Leaderboard
    </div>
    <div
      class="space-y-2 font-Kontesa text-[15px] font-bold leading-none tracking-tight text-navy 2xl:text-[18px]"
    >
      <div
        v-for="user in leaderboard"
        :key="user.id"
        class="flex gap-2 rounded-lg border border-navy bg-white px-3 py-[10px] shadow-[2px_2px_0px_#16114F]"
      >
        <span class="flex items-center">#{{ user.rank }}</span>
        <span class="flex w-[90px] lg:w-[110px] items-center whitespace-nowrap">{{
          user.name
        }}</span>
        <span class="flex items-center gap-1">
          <img class="h-5 w-5" src="~assets/images/profile/family.png" />
          {{ formatter.format(user.family) }}
        </span>
        <span class="flex items-center gap-1">
          <img class="h-5 w-5" src="~assets/images/logo-icon.png" />
          {{ formatter.format(user.points) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const formatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  notation: "compact",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const leaderboard = [
  {
    id: 1,
    rank: 1,
    name: "Ana P Rodriguez",
    family: 53,
    points: 43540,
  },
  {
    id: 2,
    rank: 2,
    name: "Seth Blaustein",
    family: 67,
    points: 34500,
  },
  {
    id: 3,
    rank: 3,
    name: "Jason Black",
    family: 32,
    points: 52120,
  },
];
</script>
