<template>
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_62_41728)">
      <path
        d="M12.4536 16.3417V9.44174H16.3964V16.3417H12.4536ZM6.53929 16.3417V0.570312H10.4821V16.3417H6.53929ZM0.625 16.3417V5.49888H4.56786V16.3417H0.625Z"
        fill="currentColor"
      />
      <path
        d="M12.4536 16.3417V9.44174H16.3964V16.3417H12.4536ZM6.53929 16.3417V0.570312H10.4821V16.3417H6.53929ZM0.625 16.3417V5.49888H4.56786V16.3417H0.625Z"
        stroke="#16114F"
        stroke-width="0.342857"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_62_41728"
        x="0.453125"
        y="0.398926"
        width="16.1172"
        height="18.1714"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.05714" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_62_41728"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_62_41728"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
