<template>
  <div ref="anim"></div>
</template>

<script lang="ts" setup>
import { type AnimationItem, type LottiePlayer } from "lottie-web";

interface LottieAnimationProps {
  src: string;
  loop?: boolean;
  autoplay?: boolean;
  speed?: number;
}

const props = withDefaults(defineProps<LottieAnimationProps>(), {
  loop: true,
  autoplay: true,
  speed: 1,
});

const emit = defineEmits<{
  (e: "loaded", animation: AnimationItem): void;
}>();

const anim = ref<HTMLElement | null>(null);
const currentAnim = ref<AnimationItem | null>(null);
const player = ref<LottiePlayer | null>(null);

onMounted(async () => {
  const el = anim.value;
  if (!el) {
    console.error("Element not found");
    return;
  }
  player.value = (await import("lottie-web")) as any;
  currentAnim.value = player.value!.loadAnimation({
    container: el,
    renderer: "svg",
    loop: props.loop,
    autoplay: props.autoplay,
    path: props.src,
  });

  currentAnim.value.addEventListener("DOMLoaded", () => {
    emit("loaded", currentAnim.value!);
  });
});

onUnmounted(() => {
  if (currentAnim.value) {
    currentAnim.value.destroy();
  }
});
</script>
