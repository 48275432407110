<template>
  <LandingPageFeaturesCardsRoot ref="el" class="relative h-full w-full">
    <div
      class="step-gradient relative h-full w-[120%] from-[#D0F7FF] to-[#61E5FF] md:w-full"
    >
      <NuxtImg
        src="/images/landing-page/scroll-animation/step-4/step-4-transparent.webp"
        class="absolute left-0 top-0 object-cover"
      />
    </div>
  </LandingPageFeaturesCardsRoot>
</template>

<script setup lang="ts">
import type { MaybeElement } from "@vueuse/core";

const el = ref<MaybeElement>();
</script>

<style lang="sass" scoped>
.step-gradient
  background-image: linear-gradient(140deg, var(--tw-gradient-stops))
</style>
