<template>
  <svg
    width="242"
    height="165"
    viewBox="0 0 242 165"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-shadow"
  >
    <g>
      <path
        d="M98.877 6.31571L123.316 25.5345L151.549 0.0454642L158.392 26.7188L197.71 9.6526L185.601 38.4975L228.218 33.2343L199.556 58.5376L237.029 66.1199L197.491 82.87L222.4 101.796L179.816 106.675L187.227 133.197L150.032 125.239L138.476 154.102L114.037 134.884L85.8045 160.373L78.9617 133.699L39.6434 150.765L51.7521 121.921L9.13584 127.184L37.7978 101.88L0.324169 94.2981L39.8625 77.548L14.9537 58.622L57.5374 53.7426L50.1268 27.2214L87.3216 35.1792L98.877 6.31571Z"
        fill="white"
      />
      <path
        d="M99.093 7.12161L123.007 25.9276L123.338 26.1881L123.651 25.9057L151.27 0.970794L157.907 26.843L158.053 27.4109L158.591 27.1775L196.767 10.6069L185.14 38.3039L184.806 39.0994L185.663 38.9937L226.676 33.9285L199.225 58.1628L198.471 58.8282L199.456 59.0277L235.347 66.2896L197.296 82.4096L196.501 82.7462L197.188 83.2682L221.106 101.441L179.759 106.179L179.177 106.245L179.334 106.81L186.522 132.535L150.136 124.75L149.724 124.662L149.568 125.053L138.26 153.296L114.346 134.49L114.015 134.23L113.702 134.512L86.0833 159.447L79.446 133.575L79.3003 133.007L78.7626 133.241L40.5863 149.811L52.2131 122.114L52.5471 121.319L51.6908 121.424L10.6777 126.49L38.1287 102.255L38.8825 101.59L37.8969 101.39L2.00657 94.1284L40.0576 78.0084L40.852 77.6719L40.165 77.1499L16.2474 58.977L57.5943 54.2394L58.1767 54.1727L58.0189 53.6081L50.8309 27.8834L87.217 35.6682L87.6291 35.7564L87.7858 35.3651L99.093 7.12161Z"
        stroke="#16114F"
      />
    </g>
  </svg>
</template>

<style>
.svg-shadow {
  @apply drop-shadow-[5px_5px_0px_#16114f];
}
</style>
