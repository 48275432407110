import dayjs from "dayjs";

export const useTimer = (targetDate: MaybeRef<Date>) => {
  const now = dayjs();
  const target = dayjs(toValue(targetDate));
  const diff = ref(target.diff(now, "second"));
  const totalSecondsInMonth = dayjs().daysInMonth() * 24 * 60 * 60;

  const shouldRun = ref(true);

  const handleTimer = () => {
    if (shouldRun.value) {
      diff.value = target.diff(dayjs(), "second");

      setTimeout(() => {
        handleTimer();
      }, 500);
    }
  };

  onMounted(() => {
    handleTimer();
  });

  onUnmounted(() => {
    shouldRun.value = false;
  });

  const days = computed(() => Math.floor(diff.value / 60 / 60 / 24));
  const hours = computed(() => Math.floor((diff.value / 60 / 60) % 24));
  const minutes = computed(() => Math.floor((diff.value / 60) % 60));
  const seconds = computed(() => Math.floor(diff.value % 60));

  const percentageProgress = computed(() => {
    return (diff.value / totalSecondsInMonth) * 100;
  });

  return {
    days,
    hours,
    minutes,
    seconds,
    percentageProgress,
    zeroPad,
  };
};

const zeroPad = (num: number) => {
  return num.toString().padStart(2, "0");
};
