import { featuresList } from "~/components/LandingPage/Features/featuresList";

export const useFeaturesList = (percentage: Ref<number>) => {
  const scrollWindow = useWindowScroll();

  const isMobile = useIsMobile();

  onMounted(() => {
    isMobile.value = window.innerWidth < 768;
  });

  const currentActiveFeatureIndex = computed(() => {
    const featureIndex = Math.floor(
      percentage.value / (100 / featuresList.length),
    );

    return Math.min(featureIndex, featuresList.length - 1);
  });

  const currentActiveFeature = computed(() => {
    return featuresList[currentActiveFeatureIndex.value] ?? null;
  });

  /* How much the user has scrolled to the next feature */
  const progressToNextFeature = computed(() => {
    const featureIndex = Math.floor(
      percentage.value / (100 / featuresList.length),
    );
    const progress =
      (percentage.value % (100 / featuresList.length)) * featuresList.length;

    return featureIndex > featuresList.length - 1 ? 100 : progress;
  });

  const listTopPosition = computed(() => {
    const offset = isMobile.value ? -120 : -60;
    return currentActiveFeatureIndex.value * offset;
  });

  return {
    scroll: scrollWindow.y,
    listTopPosition,
    percentageToNextFeature: progressToNextFeature,
    currentActiveFeature,
    currentActiveFeatureIndex,
  };
};
