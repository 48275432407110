<template>
  <div class="overflow-hidden px-4 pt-20">
    <div
      class="container mx-auto flex flex-col gap-10 rounded-3xl border-2 border-navy bg-gradient-to-t from-white to-yellow px-4 py-10 md:gap-20"
    >
      <div class="flex flex-col items-center gap-4 lg:gap-5">
        <span
          class="text-shadow text-center font-Kontesa text-h1-mobile font-bold text-white md:text-h2"
          >Featured campaign</span
        >

        <p class="text-center text-p1 font-bold uppercase text-navy md:text-h5">
          Yellow rooms bring Education into the heart of extreme poverty!
        </p>
      </div>

      <div class="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        <div
          v-motion-slide-visible-left
          class="flex flex-col gap-6 rounded-2xl bg-white p-4 shadow-sm md:col-span-2 lg:col-span-1"
        >
          <span class="hidden font-Kontesa text-h3 font-bold text-navy md:block"
            >EDUCATION THAT ENDS EXTREME POVERTY!</span
          >

          <div class="hidden border-b border-dashed border-navy md:block" />

          <div class="flex flex-col gap-4">
            <div class="flex gap-6">
              <div
                class="flex aspect-square basis-full flex-col items-center justify-center gap-2 rounded-2xl border border-navy bg-main-bg p-2"
              >
                <span class="text-4xl font-black text-navy lg:text-h3">$1</span>

                <span class="font-Kontesa text-p1 font-semibold text-navy"
                  >Donate</span
                >
              </div>

              <div
                class="flex w-[54px] shrink-0 flex-col items-center justify-center gap-3"
              >
                <div
                  v-for="index of 2"
                  :key="index"
                  class="h-[17px] w-full rounded-sm bg-white shadow-md"
                />
              </div>

              <div
                class="flex aspect-square basis-full flex-col items-center justify-center gap-2 rounded-2xl border border-navy bg-gradient-to-t from-white to-yellow p-2"
              >
                <NuxtImg
                  src="/images/landing-page/child.png"
                  alt="child picture"
                  :width="69"
                  :height="69"
                  class="h-[70px] w-[70px] rounded-xl border border-navy object-cover object-center"
                />

                <span
                  class="text-center font-Kontesa text-p2-mobile font-semibold text-navy lg:text-p1"
                  >2 days of education <br />
                  for 1 child</span
                >
              </div>
            </div>

            <div
              class="flex w-full items-center justify-between rounded-xl bg-gradient-to-t from-white to-yellow p-4 shadow-md"
            >
              <div
                class="flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-white shadow-sm"
              >
                <IconsShieldStrokeFill class="w-[14px] text-purple-medium" />
              </div>

              <div class="flex flex-col justify-around gap-8">
                <div class="relative flex flex-col gap-8">
                  <span
                    class="text-shadow text-center font-Kontesa text-h3-mobile font-extrabold uppercase text-white lg:text-h4"
                  >
                    Impact Guarantee</span
                  >
                </div>
              </div>

              <div
                class="flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-white shadow-sm"
              >
                <IconsShieldStrokeFill class="w-[14px] text-purple-medium" />
              </div>
            </div>

            <NuxtLink
              to="/campaigns/yellow-rooms"
              class="mx-auto w-full md:w-fit"
            >
              <Button variant="primary-flat" class="mt-4 w-full px-12 uppercase"
                >Open campaign</Button
              >
            </NuxtLink>
          </div>
        </div>

        <div
          v-motion-slide-visible-right
          class="col-span-1 flex flex-col gap-6 rounded-[30px] bg-white p-4 shadow-sm md:col-span-2"
          :delay="300"
        >
          <div
            class="relative h-[400px] grow overflow-hidden rounded-[26px] bg-navy shadow-sm"
          >
            <NuxtImg
              src="/images/landing-page/feature-campaigns/preview.png"
              sizes="100vw md:50vw"
              class="absolute top-0 h-full w-full object-cover object-[25%] sm:object-[10%] md:object-center"
              alt="hero thumbnail"
            />

            <Button
              class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 uppercase"
              variant="secondary"
              @click="playVideo"
            >
              Play video
            </Button>
            <VideoPlayerModal id="796820355?h=ae98d0dea9" ref="video" />

            <div
              class="pig-percentage-bg absolute right-6 top-6 rounded-[5px] border border-navy shadow-[0px_7px_0px_#16114F] md:shadow-[0px_8px_0px_#16114F]"
            >
              <div class="flex h-[30px] w-28 items-center md:block md:w-32">
                <PigPercentValueDisplay
                  :value="86"
                  percentage-font-size="text-[28px] md:text-[32px] md:leading-none"
                  pig-icon-size="h-[50px] w-[49px] md:h-[73px] md:w-[73px]"
                  top-class="py-0 pl-[50px] pr-3 md:pl-[70px]"
                  class="rounded-[5px]"
                />
              </div>
            </div>
          </div>

          <p class="max-w-[48ch] text-h5 font-medium text-navy-medium">
            Yellow Rooms are classrooms that serves as a safe space for kids in
            the heart of impoverished communities.
          </p>
        </div>

        <ReviewCard
          v-for="(review, index) of reviews"
          :key="index"
          v-bind="review"
          v-motion-slide-visible-right
          :delay="600"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VideoPlayerModal from "~/components/VideoPlayer/Modal.vue";

const reviews = [
  {
    name: "Mridhula Sridharan",
    role: "Project Manager, Asian Venture Philanthropy Network; former Associate, Dasra",
    img: "/images/campaigns-page/yellow-rooms/vettedby/sridharan.jpeg",
    desc: "“Sarthak is a scrappy organization working with a vulnerable population. $1 will go a long way in helping young children realize their dreams.”",
  },
  {
    name: "Priti Rao",
    role: "Senior Advisor, Dalberg",
    img: "/images/campaigns-page/yellow-rooms/vettedby/rao.png",
    desc: "“This is one of the most sustainable approaches to poverty alleviation.  A small donation has a ripple effect beyond just one child, into a family and into a community.”",
  },
  {
    name: "Molly McMahon",
    role: "Senior Director, IDEO",
    img: "/images/campaigns-page/yellow-rooms/vettedby/mcmahon.jpg",
    desc: "“A catalytic opportunity to change lives by providing a safe space to learn, grow, and fully be yourself.”",
  },
];

const video = ref<InstanceType<typeof VideoPlayerModal> | null>(null);
const playVideo = () => {
  video.value?.play();
};
</script>

<style lang="scss" scoped>
.pig-percentage-bg {
  background: linear-gradient(180deg, #9f94ff -81.19%, #ffffff 100.53%);
}
</style>
