<template>
  <svg
    width="105"
    height="126"
    viewBox="0 0 105 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_62_41324)">
      <path
        d="M1.5753 17.3236C26.1954 15.0854 44.1063 5.77041 50.2812 1.19859C56.4561 5.77041 74.3671 15.0854 98.9872 17.3236C99.9335 30.8083 99.7626 58.8977 98.0987 66.2943C96.5021 73.3915 89.052 93.9734 72.0187 106.039C59.0272 115.241 52.3142 119.153 50.2812 120.113C48.2483 119.153 41.5353 115.241 28.5438 106.039C11.5105 93.9734 4.06039 73.3915 2.4638 66.2943C0.799848 58.8977 0.629005 30.8083 1.5753 17.3236Z"
        fill="currentColor"
      />
      <path
        d="M50.5461 1C44.6317 5.4949 26.5575 15.0525 1.5753 17.3236C0.629005 30.8083 0.799848 58.8977 2.4638 66.2943C4.06039 73.3915 11.5105 93.9734 28.5438 106.039C42.1704 115.691 48.8897 119.523 50.5461 120.233M50.0164 1C55.9308 5.4949 74.005 15.0525 98.9872 17.3236C99.9335 30.8083 99.7627 58.8977 98.0987 66.2943C96.5021 73.3915 89.052 93.9734 72.0187 106.039C58.3921 115.691 51.6728 119.523 50.0164 120.233"
        stroke="#16114F"
        stroke-width="2"
      />
    </g>
    <path
      d="M30 60L44 75.5L70 47"
      stroke="#16114F"
      stroke-width="4"
      stroke-linecap="round"
    />
    <defs>
      <filter
        id="filter0_d_62_41324"
        x="0"
        y="0.203613"
        width="104.562"
        height="124.949"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0862745 0 0 0 0 0.0666667 0 0 0 0 0.309804 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_62_41324"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_62_41324"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CustomShieldStrokeIcon",
};
</script>
