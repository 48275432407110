<template>
  <LandingPageFeaturesCardsRoot class="relative flex w-full justify-center">
    <div
      class="card-bg flex h-full w-full flex-col gap-5 px-5 pb-5 pt-[23px] md:gap-7 md:pb-[75px] lg:px-11 lg:pt-[60px]"
    >
      <div class="flex items-center justify-around">
        <span
          class="font-Kontesa text-[28px] font-semibold uppercase tracking-[0.02em] text-navy md:text-4xl lg:text-5xl"
          >Impact Score</span
        >

        <div
          class="pig-percentage-bg rounded-[5px] border border-navy shadow-[0px_8px_0px_#16114F] md:shadow-[0px_11px_0px_#16114F]"
        >
          <div class="rounded-[5px] bg-navy">
            <LandingPageScrollAnimationStep3PigWithPercentageCounter
              :value="86"
              class="pig-percentage-bg rounded-[5px]"
            />
          </div>
        </div>
      </div>

      <!-- subheading clean up text -->
      <div
        class="relative flex rounded-xl border border-navy bg-[#61E5FF] p-2 shadow-[2px_2px_0px_#16114F]"
      >
        <NuxtImg
          src="/images/landing-page/scroll-animation/step-3/fishing-boat-scroll-animation-step-3.png"
          width="133"
          height="84"
          class="h-[49px] w-[80px] rounded-lg border border-navy lg:h-[84px] lg:w-[133px]"
        />

        <div
          class="absolute right-[33px] top-1/2 max-w-[162px] -translate-y-1/2 text-center font-Aeonik text-p2 font-bold uppercase text-navy md:right-[52px] md:max-w-[244px] md:text-p1"
        >
          Clean up the Great Pacific Garbage Patch
        </div>
      </div>

      <!-- impact score -->
      <div class="flex flex-col gap-2 lg:gap-5">
        <div
          v-for="(category, index) of categories"
          :key="category.name"
          class="flex items-center justify-between"
        >
          <span class="font-Kontesa text-p1 font-semibold uppercase text-navy">
            {{ category.name }}</span
          >

          <ProgressBarBasic
            :value="category.value"
            :delay="index * 100"
            class="w-full max-w-[50%] md:max-w-[360px]"
            white-bar-height="h-5"
            progress-ball-circle="h-[12px] w-[12px]"
          />
        </div>
      </div>

      <!-- two-vetters section -->
      <div class="relative hidden justify-between gap-4 lg:flex">
        <div class="absolute -bottom-14 -left-11 w-[130px]">
          <NuxtImg
            src="/images/landing-page/scroll-animation/step-3/magnifying-glass.png"
            width="198"
          />
        </div>

        <div
          class="flex max-h-[73px] max-w-[234px] rounded-xl border-2 border-navy bg-white"
        >
          <div class="max-w-[73px] rounded-l-[10px] border-r-2 bg-navy">
            <NuxtImg
              src="/images/landing-page/scroll-animation/step-3/chi.png"
              width="146"
              height="146"
              class="w-full rounded-l-[12px] border-r-2 border-navy"
            />
          </div>

          <div
            class="flex-1 space-y-1 pl-4 pr-2 pt-2 font-Kontesa text-p1 font-semibold text-navy"
          >
            <span>Tom Chi</span>
            <span class="block font-Aeonik text-p2 font-medium"
              >Co-founder, Google X</span
            >
          </div>
        </div>

        <div
          class="flex max-h-[73px] max-w-[234px] rounded-xl border-2 border-navy bg-white"
        >
          <div class="max-w-[73px] rounded-l-[10px] border-r-2 bg-navy">
            <NuxtImg
              src="/images/landing-page/scroll-animation/step-3/frischmann.png"
              width="146"
              height="146"
              class="w-full rounded-l-[12px] border-r-2 border-navy"
            />
          </div>

          <div
            class="flex-1 space-y-1 pl-4 pr-2 pt-2 font-Kontesa text-p1 font-semibold text-navy"
          >
            <span>Chad Frischmann</span>
            <span class="block font-Aeonik text-p2 font-medium"
              >Founder, Regenerative Intelligence</span
            >
          </div>
        </div>
      </div>
    </div>
  </LandingPageFeaturesCardsRoot>
</template>

<script lang="ts" setup>
const categories = [
  {
    value: 68,
    name: "effectiveness",
  },
  {
    value: 87,
    name: "transparency",
  },
  {
    value: 100,
    name: "track record",
  },
  {
    value: 96,
    name: "measurability",
  },
  {
    value: 43,
    name: "impact stack",
  },
  {
    value: 75,
    name: "wisdom",
  },
  {
    value: 90,
    name: "innovation",
  },
];
</script>

<style lang="scss" scoped>
.pig-percentage-bg {
  background: linear-gradient(180deg, #9f94ff -81.19%, #ffffff 100.53%);
}

.card-bg {
  background: linear-gradient(179.38deg, #ffffff -10.17%, #9f94ff 99.49%);
}
</style>
