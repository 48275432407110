<template>
  <div
    class="max-w-screen relative flex h-full w-full items-center justify-center lg:max-h-[694px]"
  >
    <div
      class="h-[400px] w-full overflow-hidden rounded-[20px] border-[3px] border-navy shadow-[3px_3px_0px_#16114F] md:h-full lg:max-w-[572px]"
    >
      <slot />
    </div>
  </div>
</template>
