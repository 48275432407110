<template>
  <div v-if="data" class="flex flex-col overflow-hidden">
    <UiScrollingMarquee :speed="60">
      <AnimationCarouselMessage
        v-for="member in firstHalfMembers"
        :key="member.id"
        :member
        class="mx-4"
      />
    </UiScrollingMarquee>
    <UiScrollingMarquee :speed="60" class="translate-x-[150px]">
      <AnimationCarouselMessage
        v-for="member in secondHalfMembers"
        :key="member.id"
        :member
        class="mx-4"
      />
    </UiScrollingMarquee>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import { landingQueries } from "~/domains/landing/queries";

const { data, suspense } = useQuery({
  ...landingQueries.marquee(),
  suspense: true,
});

const firstHalfMembers = computed(() => {
  return data.value?.slice(0, Math.ceil(data.value.length / 2));
});

const secondHalfMembers = computed(() => {
  return data.value?.slice(Math.ceil(data.value.length / 2));
});

await suspense();
</script>

<style scoped></style>
